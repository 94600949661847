import { Injectable, HostListener, OnInit } from '@angular/core';
import { Router } from "@angular/router";


declare function openChat() : any;
declare function openCard(email: string, uid: string, desc: string, val: string) : any;
declare function openBoleto(email: string, uid: string, desc: string, val: string) : any;


declare function eduzz() : any;

declare function openDemo() : any;

@Injectable({
  providedIn: 'root'
})



export class ConfigService implements OnInit {

 

  
  //public isMobileResolution: boolean;
  
  links = [1, 'hello', 5, 8];


  public demo        : boolean;
  public domain       : string;
  public nameCorp     : string;
  public fullname     : string;
  public darklogo     : string;
  public lightlogo    : string;
  public cnpj         : string;
  public telformated  : string;
  public tel          : string;
  public appname      : string;
  public app          : string;  
  public startup      : string;
  public download     : string;
  public install      : string;
  public support      : string;
  public login        : string;
  public signup       : string;
  public applogo      : string;
  public buynow       : string;
  public buydrops     : string;
  public color        : string;
  public buynowoff    : string;
  public buynowios    : string;
  public cupom        : string;


  public link_lite         : string;
  public link_pro          : string;
  public link_premium      : string;
  public link_vip          : string;


  public lite         : string;
  public pro          : string;
  public premium      : string;
  public vip          : string;
 

  public val_lite         : string;
  public val_pro          : string;
  public val_premium      : string;
  public val_vip          : string;


  public promo_val_lite         : string;
  public promo_val_pro          : string;
  public promo_val_premium      : string;
  public promo_val_vip          : string;

  public pixCode                : any;



  constructor(

    public router: Router,


    ) {



      this.demo = window.location.href.indexOf("demo") !== -1


      const parsedUrl = new URL(window.location.href);


      
      


      this.nameCorp      = "Syncsoft";
      this.domain      = "syncsoft.com.br";
      this.fullname      = "Syncsoft Softwares";
      this.darklogo      = "https://meu.espiao.com.br/assets/img/brand/app-spymaster-logo-dark.svg";
      this.lightlogo     = "https://meu.espiao.com.br/assets/img/brand/app-spymaster-logo-white.svg";
      this.cnpj          = "11.209.813/0001-02";
      this.telformated   = "+551140034960";
      this.tel           = "4004-7985";
      this.appname       = "Spymaster"; 
      this.app           = "spymaster";   
      this.startup       = "iniciar.app";
      this.download      = "https://iniciar.app";
      this.install       = "https://syncsoft.com.br/suporte/guia-de-instalacao.html";
      this.support       = "https://syncsoft.com.br/suporte/";
      this.login         = "https://meu.espiao.com.br/login";
      this.signup        = "https://meu.espiao.com.br/signup";
      this.applogo       = "https://meu.espiao.com.br/assets/img/brand/app-spymaster-logo-dark.svg";
      this.buynow        = "https://syncsoft.com.br/comprar.html";
      this.buynowios     = "https://syncsoft.com.br/comprar.html?os=ios";
      this.buydrops      = "https://syncsoft.com.br/drops.html";
      this.color         = "#0077ff";

      this.link_lite         = "https://pag.ae/7-1Tjau9o";
      this.link_pro          = "https://mpago.la/2bdh7g8";
      this.link_premium      = "https://mpago.la/2xfGLS3";
      this.link_vip          = "http://pag.ae/7-1TgDy7L";



      this.lite        = "LITE";
      this.pro         = "PRO";
      this.premium     = "PREMIUM";
      this.vip         = "VIP";
  


      this.val_lite        = "99";
      this.val_pro         = "249";
      this.val_premium     = "349";
      this.val_vip         = "149";

      this.promo_val_lite         = "99";
      this.promo_val_pro          = "249";
      this.promo_val_premium      = "349";
      this.promo_val_vip          = "149";
      

   



 

    
  }
     
   getLink(arr) {

    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
}



    openUrl(url: string){
      window.open(url, '_self');
    }
  
    openNewUrl(url: string){
      window.open(url, '_blank');
    }


    openInstall(startup: string, app: string, code: string){
      window.open('https://' + startup + '/' + code, '_blank');
    }




    //var date = new Date();
    //date.setHours(date.getHours() + 2);
    //var dateUnix = date.getUTCMilliseconds()

  



  



  callChat() {
      openChat()
  }


  callCard(email: string, uid: string, desc: string, val: string, tid: string) {

    this.openNewUrl("https://mpago.la/" + tid);

  }

  callLink(email: string, uid: string, desc: string, val: string, link: string) {

    
    this.openUrl(link);

  }

  linkLite() {
    window.open(this.link_lite, '_self');
}

linkPro() {
    window.open(this.link_pro, '_self');
}

linkPremium() {
    window.open(this.link_premium, '_self');
}

linkVip() {
    window.open(this.link_vip, '_self');
}
 
  callPay(link: string) {

    console.log(link);

   // this.openUrl(link);

  }



  callBoleto(email: string, uid: string, desc: string, val: string) {


    openBoleto(email, uid, desc, val)
  }

  callDemo() {
    openDemo()
  }

  


  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  
    



 
  

}
















   